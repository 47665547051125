import React from "react";

const AboutBlock2 = () => {
    return (
        <div className="about_redLine_block">
            <h1>Welcome to #1 AC GUYS</h1>
        </div>
    );
};

export default AboutBlock2;
