import React from "react";

const LocationBlock1 = () => {
    return (
        <div className="location_block1 container">
            <p>Locations</p>
            <h1>Our team is always happy to help you</h1>
        </div>
    );
};

export default LocationBlock1;
