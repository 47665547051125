import BookNow from "../components/BookNow/BookNow";

const BookPage = () => {
    return (
        <div>
            <BookNow />
        </div>
    );
};

export default BookPage;
