import Services from "../components/Services/Services";

const ServicesPage = () => {
    return (
        <div>
           <Services />
        </div>
    );
}

export default ServicesPage;
