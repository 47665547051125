import React from "react";
import AboutUs from "../components/aboutUs/AboutUs";

const AboutUsPage = () => {
  return (
    <div>
      <AboutUs />
    </div>
  );
};

export default AboutUsPage;
