import React from "react";
import Equipmentfirst from "../components/Equipment/EqFirst/Equipmentfirst";

const EqFirstPage = () => {
  return (
    <div>
      <Equipmentfirst />
    </div>
  );
};
export default EqFirstPage;
