import BlogSingle from "../components/Blogs/BlogSingle/BlogSingle";

const BlogSinglePage = () => {
    return (
        <div>
            <BlogSingle />
        </div>
    );
};

export default BlogSinglePage;
