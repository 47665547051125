import EqSecond from "../components/Equipment/EqSecond/EqSecond";

const EquipmentPage = () => {
    return (
        <div>
            <EqSecond />
        </div>
    );
};

export default EquipmentPage;
