import "./services.scss";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Enjeneers from "../Home/block/block5/Enjeneers";
import Block7 from "../Home/block/block7/Block7";
import Block8 from "../Home/block/block8/Block8";
import OurProjectsPhotos from "../Home/block/block9/OurProjectsPhotos";
import LatestNews from "../Home/block/block10/LatestNews";
import Partners from "../Home/block/block11/Partners";
import SectionFamily from "./sections/SectionFamily";

const Services = () => {
    return (
        <div>
            <SectionFamily />
            <Section1 />
            <Section2 />
            <Section3 />
            <Enjeneers />
            <OurProjectsPhotos />
            <LatestNews />
            <Partners />
        </div>
    );
};

export default Services;
