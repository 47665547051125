import * as React from "react";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

// function ValueLabelComponent(props) {
//     const { children, value } = props;

//     return (
//         <Tooltip enterTouchDelay={0} placement="top" title={value}>
//             {children}
//         </Tooltip>
//     );
// }

// ValueLabelComponent.propTypes = {
//     children: PropTypes.element.isRequired,
//     value: PropTypes.number.isRequired,
// };
const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const marks = [
    {
        value: 0,
    },
    {
        value: 20,
    },
    {
        value: 37,
    },
    {
        value: 100,
    },
];

const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? " #00C583" : " #E7E2E2",
    height: 1,
    padding: "15px 0",
    "& .MuiSlider-thumb": {
        height: 18,
        width: 18,
        backgroundColor: "#fff",
        border: "6px solid #00C583",
        boxShadow: iOSBoxShadow,
        "&:focus, &:hover, &.Mui-active": {
            boxShadow:
                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    "& .MuiSlider-valueLabel": {
        fontSize: 12,
        fontWeight: "normal",
        top: -6,
        backgroundColor: "unset",
        color: theme.palette.text.primary,
        "&:before": {
            display: "none",
        },
        "& *": {
            background: "transparent",
            color: theme.palette.mode === "dark" ? "#fff" : "#000",
        },
    },
    "& .MuiSlider-track": {
        border: "none",
    },
    "& .MuiSlider-rail": {
        opacity: 0.5,
        backgroundColor: "#bfbfbf",
    },
    "& .MuiSlider-mark": {
        backgroundColor: "#bfbfbf",
        height: 8,
        width: 1,
        "&.MuiSlider-markActive": {
            opacity: 1,
            backgroundColor: "currentColor",
        },
    },
}));

export default function EqSecondSlider() {
    const [value, setValue] = React.useState(10);

    return (
        <Box sx={{ width: "100%" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                }}
            >
                <p>$100</p> <p>$10000</p>
            </div>
            <IOSSlider
                aria-label="ios slider"
                defaultValue={10}
                min={100}
                max={10000}
                // valueLabelDisplay="on"
                step={100}
                // valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
                value={value}
                scale={setValue}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                }}
            >
                <p>Min</p>
                <p id="non-linear-slider" gutterBottom>
                    ${value}
                </p>
                <p>Max</p>
            </div>
        </Box>
    );
}
