import Location from "../components/Location/Location";

const LocationPage = () => {
    return (
        <div>
            <Location />
        </div>
    );
};

export default LocationPage;
