import Blog from "../components/Blogs/Blog/Blog";

const BlogPage = () => {
    return (
        <div>
            <Blog />
        </div>
    );
};

export default BlogPage;
