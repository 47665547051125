import ServicesMore from "../components/ServicesMore/ServicesMore";

const ServicesMorePage = () => {
    return (
        <div>
            <ServicesMore />
        </div>
    );
}

export default ServicesMorePage;
