import BestOffers2 from "../components/BestOffers/BestOffers2/BestOffers2";

const BestOffers12Page = () => {
    return (
        <div>
            <BestOffers2 />
        </div>
    );
};

export default BestOffers12Page;
