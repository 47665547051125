import BestOffers1 from "../components/BestOffers/BestOffers1/BestOffers1";

const BestOffersPage = () => {
    return (
        <div>
            <BestOffers1 />
        </div>
    );
};

export default BestOffersPage;
