import Photos from "../components/Photos/Photos";

const PhotosPage = () => {
    return (
        <div>
            <Photos />
        </div>
    );
};

export default PhotosPage;
